<template>
  <div class="row mx-0 mt-5 justify-content-center" v-if="currentPosters && currentPosters.length > 0">
    <div class="col-12 text-center green-text title my-4">
      LATEST APPEALS
    </div>
    <div class="col-12 text-center grey_bg py-5 px-4 d-none d-md-block" v-if="!isMobile && !isApp">
      <div class="container-lg" >
        <div class="row m-0 justify-content-center">
          <div class="col-12 p-0">
            <NewSlider :posters="currentPosters" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-center p-4 d-md-none" v-else>
      <LatestAppealsSliderMobile :posters="currentPosters" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    NewSlider: defineAsyncComponent(() => import('./NewSlider.vue')),
    LatestAppealsSliderMobile: defineAsyncComponent(() => import('./slider/LatestAppealsSliderMobile.vue'))
  },
  name: 'Latest Appeals Slider',
  computed: {
    ...mapGetters([
      'currentPosters',
      'isMobile',
      'isApp'
    ])
  }
}
</script>

<style scoped>
.title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.2em;
  letter-spacing: 8px;
}

@media (min-width: 992px) {
  .title {
    font-family: "quicksand_medium", Sans-serif;
    font-size: 29px;
    font-weight: 500;
    line-height: 1.3em;
    letter-spacing: 8px;
  }
}
</style>
